import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Img from "gatsby-image";
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import ArtDirectedBackground from "../components/BGImage";
import "../utils/normalize.css";
import "../utils/css/screen.css";
import { useIntl } from "react-intl";
import ReactPlayer from "react-player";
import SpotifyPlayer from 'react-spotify-player';

const BlogIndex = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title;
  const size = {
    width: '100%',
    height: 500,
  };
  const view = 'list'; // or 'coverart'
  const theme = 'black'; // or 'white'

  const intl = useIntl();
  return (
    <Layout title={siteTitle}>
      <SEO
        title={intl.formatMessage({ id: "official" })}
        description={intl.formatMessage({ id: "desc" })}
        keywords={[`pianist`, `prodigy`, `classical musician`, `piano`]}
      />

      <ArtDirectedBackground
        title1={intl.formatMessage({ id: "title1" })}
        title2={intl.formatMessage({ id: "title2" })}
        title3={intl.formatMessage({ id: "title3" })}
        image={data.IlyunCircle.fluid}
        data={data}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2 id="this-is-a-section">
            {intl.formatMessage({ id: "teachers" })}
          </h2>
          <hr />
          <div className="galleryContainer">

            <div className="NewsParentFigure">
              <Img
                fluid={data.Pavel.fluid}
                alt="Pavel Glilov & İlyun Bürkev"
                className="NewsItselfLarge"
              />
              <h4>Pavel Glilov</h4>
              <p className="introHeight">
                {intl.formatMessage({ id: "pavel" })}
              </p>
              <Link to="/with-prof-pavel-glilov">
                <ul className="actions fit">
                  <li className="button primary fit">
                    {intl.formatMessage({ id: "read" })}
                  </li>
                </ul>
              </Link>
            </div>


            <div className="NewsParentFigure">
              <Img
                fluid={data.GulsinOnay.fluid}
                alt="Gülsin Onay & İlyun Bürkev"
                className="NewsItselfLarge"
              />
              <h4>Gülsin Onay</h4>
              <p className="introHeight">
                {intl.formatMessage({ id: "gulsin" })}
              </p>
              <Link to="/with-pianist-gulsin-onay">
                <ul className="actions fit">
                  <li className="button primary fit">
                    {intl.formatMessage({ id: "read" })}
                  </li>
                </ul>
              </Link>
            </div>

            <div className="NewsParentFigure">
              <Img
                fluid={data.imageSharp.fluid}
                alt="Burcu Aktaş Urgun & İlyun Bürkev"
                className="NewsItselfLarge"
              />
              <h4>Burcu Aktaş Urgun</h4>
              <p className="introHeight">
                {intl.formatMessage({ id: "burcu" })}
              </p>
              <Link to="/with-prof-burcu-aktas-urgun">
                <ul className="actions fit">
                  <li className="button primary fit">
                    {intl.formatMessage({ id: "read" })}
                  </li>
                </ul>
              </Link>
            </div>

            <div className="NewsParentFigure">
              <Img
                fluid={data.Gereon.fluid}
                alt="Gereon Kleiner & İlyun Bürkev"
                className="NewsItselfLarge"
              />
              <h4>Gereon Kleiner</h4>
              <p className="introHeight">
                {intl.formatMessage({ id: "gereon" })}
              </p>
              <Link to="/with-prof-gereon-kleiner">
                <ul className="actions fit">
                  <li className="button primary fit">
                    {intl.formatMessage({ id: "read" })}
                  </li>
                </ul>
              </Link>
            </div>

          </div>
          <hr />
          <div className="galleryContainer">
            <div className="NewsParentFigure">

              <Img
                fluid={data.Concert.fluid}
                alt="İlyun Bürkev Concert"
                className="NewsItselfLarge"
              />
              <h4>{intl.formatMessage({ id: "nav4" })}</h4>
              <Link to="/concerts">
                <ul className="actions fit">
                  <li className="button primary fit">
                    {intl.formatMessage({ id: "read" })}
                  </li>
                </ul>
              </Link>
            </div>

            <div className="NewsParentFigure">

              <Img
                fluid={data.Competition.fluid}
                alt="İlyun Bürkev Competitions"
                className="NewsItselfLarge"
              />
              <h4>{intl.formatMessage({ id: "compet" })}</h4>
              <Link to="/competitions">
                <ul className="actions fit">
                  <li className="button primary fit">
                    {intl.formatMessage({ id: "read" })}
                  </li>
                </ul>
              </Link>
            </div>
          </div>

          <h2 id="this-is-a-section">Spotify</h2>
          <hr />
          <div className="galleryContainerVideos">
            <SpotifyPlayer
              uri="spotify:artist:0bfOHqirNeXsaAoSdNSiBF"
              size={size}
              view={view}
              theme={theme}
            />
          </div>

          <h2 id="this-is-a-section">{intl.formatMessage({ id: "videos" })}</h2>
          <hr />


          <div className="galleryContainerVideos">
            <ReactPlayer
              className="imageVideoFigure"
              url="https://youtu.be/NQAfDXDV_QA"
              light={true}
              config={{
                youtube: {
                  playerVars: {
                    modestbranding: 1,
                    responsive: 1,
                    fs: 0
                  }
                }
              }}
            />

            <ReactPlayer
              className="imageVideoFigure"
              url="https://youtu.be/CvGR8dfygBI"
              light={true}
              config={{
                youtube: {
                  playerVars: {
                    modestbranding: 1,
                    responsive: 1,
                    fs: 0
                  }
                }
              }}
            />
          </div>

          <Link to="/videos">
            <ul className="actions fit">
              <li className="button primary fit">
                {intl.formatMessage({ id: "watch" })}
              </li>
            </ul>
          </Link>
        </div>
      </article>
    </Layout>
  );
};

const indexQuery = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
        GulsinOnay: imageSharp(
            fluid: { originalName: { eq: "ilyun-burkev-pianist-onay-2.jpg" } }
        ) {
            fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }

        IlyunCircle: imageSharp(
            fluid: { originalName: { eq: "ilyun-burkev-pianist-circle-2.jpg" } }
        ) {
            fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
        
        Gereon: imageSharp(fluid: { originalName: { eq: "burkev-kleiner-2.jpg" } }) {
            fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
        Pavel: imageSharp(fluid: { originalName: { eq: "burkev-pavel.jpg" } }) {
            fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
        Candy: imageSharp(fluid: { originalName: { eq: "with-my-dog-candy.jpg" } }) {
            fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
        Hobbies: imageSharp(fluid: { originalName: { eq: "my-hobbies.jpg" } }) {
            fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
        Concert: imageSharp(fluid: { originalName: { eq: "ilyun-burkev-concert-2.jpg" } }) {
            fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
        Competition: imageSharp(fluid: { originalName: { eq: "ilyun-burkev-competition-2.jpg" } }) {
            fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
        imageSharp(
            fluid: { originalName: { eq: "ilyun-burkev-burcu-aktas-2.jpg" } }
        ) {
            fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
        mobileImage: file(relativePath: { eq: "ilyun-burkev-mobile.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 1080) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        desktopImage: file(relativePath: { eq: "ilyun-burkev-desktop.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <BlogIndex location={props.location} props data={data} {...props} />
    )}
  />
)
