import * as React from "react"
import styled from "@emotion/styled"
import Particles from "react-particles-js"
import { motion } from "framer-motion"
import BackgroundImage from "gatsby-background-image"
import { css } from "@emotion/core"
import Img from "gatsby-image";
function ArtDirectedBackground({ title1, title2, title3, data, className, image }) {

  const BgImage = styled(BackgroundImage)`
    width: 100%;
    overflow: hidden;
    min-height: 100vh;
    position: relative;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.5);
  `

  const BlackOverlay = styled.div`
    width: 100%;
    overflow: hidden;
    max-height: 100vh;
    height: 100vh !important;
    position: absolute;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
    @media (max-width: 1024px) {
      background-color: rgba(0, 0, 0, 0.3);
    }
  `
  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 1024px)`,
    },
  ]

  return (
    <>
    <BgImage
      Tag={`div`}
      id={`media-test`}
      className={className}
      fluid={sources}
    >
      <BlackOverlay />
      <Particles
        className="snowFull"
        focusable="true"
        aria-hidden="true"
        params={{
          particles: {
            number: {
              value: 75,
              density: {
                enable: true,
                value_area: 1000,
              },
            },
            color: {
              value: "#f5f5f5",
            },
            shape: {
              type: ["image"],
              image: [
                {
                  src: "./musical-note1.png",
                  height: 85,
                  width: 85,
                },
                {
                  src: "./musical-note2.png",
                  height: 85,
                  width: 85,
                },
                {
                  src: "./musical-note3.png",
                  height: 85,
                  width: 85,
                },
              ],
              stroke: {
                width: 0,
                color: "#ccc",
              },
              polygon: {
                nb_sides: 10,
              },
            },
            opacity: {
              value: 0.7,
              random: true,
              anim: {
                enable: true,
                speed: 0.2,
                opacity_min: 0.3,
                sync: true,
              },
            },
            size: {
              value: 8,
              random: true,
              anim: {
                enable: true,
                speed: 0.2,
                size_min: 4,
                sync: true,
              },
            },
            line_linked: {
              enable: false,
              distance: 200,
              color: "#000",
              opacity: 1,
              width: 2,
            },
            move: {
              enable: true,
              speed: 0.2,
              direction: "top",
              random: true,
              straight: false,
              out_mode: "out",
              bounce: true,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200,
              },
            },
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onhover: {
                enable: true,
                mode: "bounce",
              },
              onclick: {
                enable: true,
                mode: "push",
              },
              resize: true,
            },
            modes: {
              grab: {
                distance: 600,
                line_linked: {
                  opacity: 1,
                },
              },
              bubble: {
                distance: 500,
                size: 40,
                duration: 2,
                opacity: 8,
                speed: 3,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
              push: {
                particles_nb: 4,
              },
              remove: {
                particles_nb: 2,
              },
            },
          },
          retina_detect: true,
        }}
      />

      <div className="mainPageComps-header">
        {/*        <div className="mainPageComps-left-header">

          <Img
            fluid={image}
            alt="Pianist İlyun Bürkev"
            className="mainPageComps-left-header-image"
          />
        </div> */}


        <div className="mainPageComps-right-header">
          <h2>
            <motion.span
              className="spanIntro"
              animate={{ color: ["#f5f5f5", "#eae0c6", "#f5f5f5"] }}
              transition={{ ease: "linear", duration: 3, loop: Infinity }}
            >
              <span css={css`font-weight: 400`}>
              {title1} <br />
              {title2} <br />
              </span>
              {title3}

            </motion.span>
          </h2>

        </div>
      </div>
    </BgImage>
      </>
  )
}

export default ArtDirectedBackground
